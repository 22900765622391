import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import img1 from "../Assets/1.png";
import img2 from "../Assets/2.png";
import img3 from "../Assets/3.png";
import img4 from "../Assets/alaa.png";
import img5 from "../Assets/kosar.png";
import img6 from "../Assets/walid.png";
import img7 from "../Assets/maz.png";
import d5 from "../Assets/d5.png";
import img8 from "../Assets/4.png";
import img9 from "../Assets/5.png";
import img10 from "../Assets/6.png";
import img11 from "../Assets/rosh.png";
import img12 from "../Assets/hal.png";
import img13 from "../Assets/9.png";
import img14 from "../Assets/10.png";
import img15 from "../Assets/15.png";
import img16 from "../Assets/11.png";
import img17 from "../Assets/14.png";
import img18 from "../Assets/13.png";
import Saadi from "../Assets/saadi.png";
import Farhad from "../Assets/farhad.png";
import Mushir from "../Assets/mushir.png";
import Bilal from "../Assets/bilal.png";
import Jinan from "../Assets/jinan.png";
import AnimatedCard from "./AnimatedSection";


import "../Styles/Testimonials.css";  
function Testimonials() {
  return (
    <section>
      <Container id="testimonials" className="my-5">
        <h2
          style={{
            textAlign: "center",
            color: "#483285",
            fontWeight: "bold",
            margin: 20,
          }}
        >
          What Our Doctors Say
        </h2>
        <div className="scrolling-wrapper">
          <div className="scrolling-card">
            <AnimatedCard delay={0.1}>
              <Card>
                <Card.Img variant="top" src={img1} />
                <Card.Body>
                  <Card.Title>Dr.Zryan Xoshnaw</Card.Title>
                  <Card.Text>
                    "Epilyum has revolutionized my practice! The results are
                    incredible, and my patients are thrilled."
                  </Card.Text>
                </Card.Body>
              </Card>
            </AnimatedCard>
          </div>
          <div className="scrolling-card">
            <AnimatedCard delay={0.2}>
              <Card>
                <Card.Img variant="top" src={img2} />
                <Card.Body>
                  <Card.Title>Dr.Shilan Barnzjy</Card.Title>
                  <Card.Text>
                    "I highly recommend Epilyum for clinics seeking effective
                    and reliable laser hair removal technology."
                  </Card.Text>
                </Card.Body>
              </Card>
            </AnimatedCard>
          </div>
          <div className="scrolling-card">
            <AnimatedCard delay={0.3}>
              <Card>
                <Card.Img variant="top" src={img3} />
                <Card.Body>
                  <Card.Title>Dr.Sherko Wais</Card.Title>
                  <Card.Text>
                    "Outstanding experience! The device is top-notch and the
                    support from Epilyum is excellent."
                  </Card.Text>
                </Card.Body>
              </Card>
            </AnimatedCard>
          </div>
          <div className="scrolling-card">
            <AnimatedCard delay={0.4}>
              <Card>
                <Card.Img variant="top" src={img4} />
                <Card.Body>
                  <Card.Title>Dr.Alaa Alsumadi</Card.Title>
                  <Card.Text>
                    "Exceptional performance and reliable results with Epilyum."
                  </Card.Text>
                </Card.Body>
              </Card>
            </AnimatedCard>
          </div>
          <div className="scrolling-card">
            <AnimatedCard delay={0.5}>
              <Card>
                <Card.Img variant="top" src={img5} />
                <Card.Body>
                  <Card.Title>Dr.Kosar Salahadin</Card.Title>
                  <Card.Text>
                    "Highly efficient and safe for all skin types. Great
                    experience!"
                  </Card.Text>
                </Card.Body>
              </Card>
            </AnimatedCard>
          </div>
          <div className="scrolling-card">
            <AnimatedCard delay={0.6}>
              <Card>
                <Card.Img variant="top" src={img6} />
                <Card.Body>
                  <Card.Title>Dr.Walid Saadi</Card.Title>
                  <Card.Text>
                    "A game-changer in laser hair removal technology. Very
                    satisfied."
                  </Card.Text>
                </Card.Body>
              </Card>
            </AnimatedCard>
          </div>
          <div className="scrolling-card">
            <AnimatedCard delay={0.6}>
              <Card>
                <Card.Img variant="top" src={img7} />
                <Card.Body>
                  <Card.Title>Dr.Mazn Dizayi</Card.Title>
                  <Card.Text>
                  "Remarkably effective with minimal discomfort, suitable for all skin tones and types."
                  </Card.Text>
                </Card.Body>
              </Card>
            </AnimatedCard>
          </div>
          <div className="scrolling-card">
            <AnimatedCard delay={0.6}>
              <Card>
                <Card.Img variant="top" src={d5} />
                <Card.Body>
                  <Card.Title>Dr.Maha alobaidi</Card.Title>
                  <Card.Text>
                  "Epilyum offers advanced hair removal with safety and precision, ideal for every skin type."
                  </Card.Text>
                </Card.Body>
              </Card>
            </AnimatedCard>
          </div>
          <div className="scrolling-card">
            <AnimatedCard delay={0.6}>
              <Card>
                <Card.Img variant="top" src={img12} />
                <Card.Body>
                  <Card.Title>Dr.Halwan Azad</Card.Title>
                  <Card.Text>
                  "Epilyum ensures exceptional results with minimal discomfort, redefining hair removal for your patients."
                  </Card.Text>
                </Card.Body>
              </Card>
            </AnimatedCard>
          </div>
          <div className="scrolling-card">
            <AnimatedCard delay={0.6}>
              <Card>
                <Card.Img variant="top" src={img10} />
                <Card.Body>
                  <Card.Title>Dr.Karzan Zrar</Card.Title>
                  <Card.Text>
                  "Designed for experts, Epilyum laser combine power and gentleness, catering to diverse patient needs."
                  </Card.Text>
                </Card.Body>
              </Card>
            </AnimatedCard>
          </div>
          
          <div className="scrolling-card">
            <AnimatedCard delay={0.6}>
              <Card>
                <Card.Img variant="top" src={img11} />
                <Card.Body>
                  <Card.Title>Dr.Roshan Dara</Card.Title>
                  <Card.Text>
                "Bring the future of hair removal to your clinic with Epilyum laser technology."

                  </Card.Text>
                </Card.Body>
              </Card>
            </AnimatedCard>
          </div>
          <div className="scrolling-card">
            <AnimatedCard delay={0.6}>
              <Card>
                <Card.Img variant="top" src={img14} />
                <Card.Body>
                  <Card.Title>Dr.Hanar Ahmed</Card.Title>
                  <Card.Text>
                  "Achieve superior results with Epilyum’s advanced technology—where safety meets effectiveness."

                  </Card.Text>
                </Card.Body>
              </Card>
            </AnimatedCard>
          </div>
          <div className="scrolling-card">
            <AnimatedCard delay={0.6}>
              <Card>
                <Card.Img variant="top" src={img13} />
                <Card.Body>
                  <Card.Title>Dr.Eman Hasan</Card.Title>
                  <Card.Text>
                  "Epilyum laser is tailored for precision, efficiency, and patient comfort in every session."

                  </Card.Text>
                </Card.Body>
              </Card>
            </AnimatedCard>
          </div>
          <div className="scrolling-card">
            <AnimatedCard delay={0.6}>
              <Card>
                <Card.Img variant="top" src={img16} />
                <Card.Body>
                  <Card.Title>Dr.Hawar Jalil</Card.Title>
                  <Card.Text>
                  "Transform your clinic experience with Epilyum’s innovative laser hair removal technology."

                  </Card.Text>
                </Card.Body>
              </Card>
            </AnimatedCard>
          </div>
          <div className="scrolling-card">
            <AnimatedCard delay={0.6}>
              <Card>
                <Card.Img variant="top" src={img9} />
                <Card.Body>
                  <Card.Title>Dr.Hoshmand Ali</Card.Title>
                  <Card.Text>
                  "Epilyum: Where innovation meets reliability for seamless hair removal treatments your patients will love."

                  </Card.Text>
                </Card.Body>
              </Card>
            </AnimatedCard>
          </div>
          <div className="scrolling-card">
            <AnimatedCard delay={0.6}>
              <Card>
                <Card.Img variant="top" src={img8} />
                <Card.Body>
                  <Card.Title>Dr.Amanj Hama Salh</Card.Title>
                  <Card.Text>
                  "Trusted by professionals, Epilyum delivers precision and patient satisfaction in every treatment."

                  </Card.Text>
                </Card.Body>
              </Card>
            </AnimatedCard>
          </div>
          <div className="scrolling-card">
            <AnimatedCard delay={0.6}>
              <Card>
                <Card.Img variant="top" src={img15} />
                <Card.Body>
                  <Card.Title>Dr.Shaxawan Mohammed</Card.Title>
                  <Card.Text>
                  "Enhance your clinic with Epilyum laser technology, ensuring safe and effective hair removal for all skin tones."
                  </Card.Text>
                </Card.Body>
              </Card>
            </AnimatedCard>
          </div>
          <div className="scrolling-card">
            <AnimatedCard delay={0.6}>
              <Card>
                <Card.Img variant="top" src={img17} />
                <Card.Body>
                  <Card.Title>Dr.Chiman Yousif</Card.Title>
                  <Card.Text>
                  "Epilyum offers advanced hair removal with safety and precision, ideal for every skin type."
                  </Card.Text>
                </Card.Body>
              </Card>
            </AnimatedCard>
          </div>
          <div className="scrolling-card">
            <AnimatedCard delay={0.6}>
              <Card>
                <Card.Img variant="top" src={img18} />
                <Card.Body>
                  <Card.Title>Dr.Pary Osman</Card.Title>
                  <Card.Text>
                  "Epilyum redefines laser hair removal with technology that adapts to all skin types and tones."
                  </Card.Text>
                </Card.Body>
              </Card>
            </AnimatedCard>
          </div>
         
          <div className="scrolling-card">
            <AnimatedCard delay={0.6}>
              <Card>
                <Card.Img variant="top" src={Bilal} />
                <Card.Body>
                  <Card.Title>Dr.Bilal Rashed</Card.Title>
                  <Card.Text>
                  "Epilyum offers a personalized laser hair removal experience, combining technology and care for optimal results."
                  </Card.Text>
                </Card.Body>
              </Card>
            </AnimatedCard>
          </div>
          <div className="scrolling-card">
            <AnimatedCard delay={0.6}>
              <Card>
                <Card.Img variant="top" src={Farhad} />
                <Card.Body>
                  <Card.Title>Dr.Farhad Jaff</Card.Title>
                  <Card.Text>
                  "Smooth, radiant skin is within reach with Epilyum’s laser hair removal, designed for all skin types."
                  </Card.Text>
                </Card.Body>
              </Card>
            </AnimatedCard>
          </div>
          <div className="scrolling-card">
            <AnimatedCard delay={0.6}>
              <Card>
                <Card.Img variant="top" src={Mushir} />
                <Card.Body>
                  <Card.Title>Dr.Mushir Ibrahem</Card.Title>
                  <Card.Text>
                  "Epilyum's laser hair removal treatments are crafted to provide safe and effective care for every skin tone."
                  </Card.Text>
                </Card.Body>
              </Card>
            </AnimatedCard>
          </div>
          <div className="scrolling-card">
            <AnimatedCard delay={0.6}>
              <Card>
                <Card.Img variant="top" src={Jinan} />
                <Card.Body>
                  <Card.Title>Dr.Jinan Jalil</Card.Title>
                  <Card.Text>
                  "Tailored for your needs, Epilyum’s laser hair removal delivers exceptional results no matter your skin type."
                  </Card.Text>
                </Card.Body>
              </Card>
            </AnimatedCard>
          </div>
          <div className="scrolling-card">
            <AnimatedCard delay={0.6}>
              <Card>
                <Card.Img variant="top" src={Saadi} />
                <Card.Body>
                  <Card.Title>Dr.Saadi Xalil</Card.Title>
                  <Card.Text>
                  "With Epilyum, achieve hair-free skin through laser treatments that prioritize comfort and effectiveness."
                  </Card.Text>
                </Card.Body>
              </Card>
            </AnimatedCard>
          </div>
        </div>
      </Container>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          fill="#151B54"
          fill-opacity="1"
          d="M0,288L48,250.7C96,213,192,139,288,122.7C384,107,480,149,576,181.3C672,213,768,235,864,208C960,181,1056,107,1152,85.3C1248,64,1344,96,1392,112L1440,128L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
        ></path>
      </svg>
    </section>
  );
}

export default Testimonials;
