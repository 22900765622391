import React from "react";

const MapComponent = () => {
  return (
    <div style={styles.mapContainer}>
      <iframe
   src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3220.3524017802956!2d44.02167047966153!3d36.182310495014754!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4007230064b1aad3%3A0x3d0ac3148fbba76f!2sEpilyum%20Laser!5e0!3m2!1sen!2siq!4v1736802955411!5m2!1sen!2siq" 
        width="100%"
        height="500px"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
  
    </div>
  );
};

const styles = {
  mapContainer: {
    width: "90%",
    height: "500px",
    margin: "20px auto",
    boxShadow: "0 10px 20px rgba(0, 0, 0, 0.1)",
    borderRadius: "10px",
    overflow: "hidden",
  },
};

export default MapComponent;
