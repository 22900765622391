import React from "react";
import { Container, Row, Col, Card, Carousel,Image } from "react-bootstrap";
import img1 from "../Assets/epiwhite.png";
import img2 from "../Assets/aac.png";
import "../Styles/FeaturesStyle.css";
import sc1 from "../Assets/sc1.png"
import sc2 from "../Assets/sc2.png"
import sc3 from "../Assets/sc3.png"
import sc4 from "../Assets/sc4.png"
import fe1 from "../Assets/fe1.png"
import fe2 from "../Assets/fe2.png"
import fe3 from "../Assets/fe3.png"
import series1 from "../Assets/series1.png"
import series2 from "../Assets/series2.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {

  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import Featurevideos from "./Featurevideos";

const Features = () => {
  const phoneNumber = "9647505398000";
  return (
    <div id="features" className="py-5" style={{width:"100%",height:"100%"}}>
      
      <Row className="justify-content-center">
          <Col lg={8}>
          <a
       href={`https://wa.me/${phoneNumber}`}// Replace with your WhatsApp number
      target="_blank"
      rel="noopener noreferrer"
      className="whatsapp-icon"
    >
  <FontAwesomeIcon icon={faWhatsapp} style={{width:30,height:30}} />
    </a>

            
          </Col>
        </Row>
        <Container>
        <Row>
          <Featurevideos/>
        </Row>
        </Container>
       
        <Row className="firstPage" lg={12}>
       
        <img src={sc1} style={{width:"100%",height:"100%",color:"blue"}}/>
        
        <img src={sc2} style={{width:"100%",height:"100%",color:"blue",marginTop:"20px"}}/>
        <img src={sc3} style={{width:"100%",height:"100%",color:"blue",marginTop:"20px"}}/>
        <img src={sc4} style={{width:"100%",height:"100%",color:"blue",marginTop:"20px"}}/>
        <img src={fe1} style={{width:"100%",height:"100%",color:"blue"}}/>
        <img src={fe2} style={{width:"100%",height:"100%",color:"blue"}}/>
        <img src={fe3} style={{width:"100%",height:"100%",color:"blue"}}/>
        </Row>
  
        <br/>
        <br/>
        <div className="container" >
      <Row className="mb-5">
        <div className="col-lg-6 col-md-12 mb-4">
          <Card style={{ backgroundColor: "#151B54" }}>
            <div className="d-flex justify-content-center align-items-center" style={{ width: "100%" }}>
              <h2 className="text-center text-white mt-3" style={{width:"90%"}}>Available Colors : Series 2024</h2>
            </div>
            <Card.Body>ll
              <Carousel prevLabel="click" nextLabel="click">
                
                <Carousel.Item>
                  <hr style={{ color: "#fff" }} />
                  <img
                    className="d-block mx-auto"
                    src={img2}
                    alt="Second slide"
                    style={{ maxWidth: "300px", maxHeight: "450px", width: "100%", height: "auto" }}
                  />
                </Carousel.Item>
              </Carousel>
            </Card.Body>
          </Card>
        </div>

        <div className="col-lg-6 col-md-12 mb-4">
          <Card style={{ backgroundColor: "#151B54" }}>
            <div className="d-flex justify-content-center align-items-center" style={{ width: "100%" }}>
              <h2 className="text-center text-white mt-3" style={{width:"90%"}}>Available Colors : Series 2025</h2>
            </div>
            <Card.Body>
             
                
              
                  <hr style={{ color: "#fff" }} />
                  <img
                    className="d-block mx-auto"
                    src={series2}
                    alt="Second slide"
                    style={{ maxWidth: "300px", maxHeight: "500px", width: "100%", height: "auto" }}
                  />
         
            
            </Card.Body>
          </Card>
        </div>
      </Row>
    </div>
        {/* <Row className="firstPage">
          <Col lg={12} >
          <div >
            <div style={{flexDirection:"row",display:"flex",justifyContent:"center",alignItems:"center",backgroundColor:"transparent"}}>
          <img src={logo} style={{width:"200px",height:"200px",color:"blue"}}/>
          <h1 style={{fontSize:60,fontWeight:"bold",color:"#151B54"}}>Epilyum Pro Series </h1><span style={{marginTop:"90px",marginRight:"40px",fontSize:"20px",color:"#151B54",fontWeight:"bold"}}>2024</span>
          
          </div>
          <hr style={{width:"40%",alignSelf:"center",display:"flex",justifySelf:"center",textAlign:"center"}}/>
          <div>
            
       
          <p>discover the concenience</p>
          </div>
          </div>
         
       
         
      
     
          <Col lg={12} style={{display:"flex",flexDirection:"row",justifyContent:"space-evenly"}}>
          <Col lg={6} style={{margin:"90px"}}>
          <Card.Title style={{width:"40%",textAlign:"center"}}>
          “ Experience the confidence of flawless skin with our clinically proven laser treatments 
          </Card.Title>
          <img src={skin}  style={{width:"50%",height:"40%"}}/>
          </Col>
          <Col lg={6}>
          <img src={device}  style={{width:"50%",height:"80%"}}/>
          
          </Col>
          </Col>
          </Col>
        </Row> */}
       
 
    </div>
  );
};

export default Features;
